import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";

//#region [Styles]
const SContentWrapper = styled.div`
	width: 100%;
	max-width: ${theme.maxContentWidth}px;

	@media ${theme.mq.desktop} {
		margin: 0 auto;
		padding: 0 15px;
        background-color: ${theme.bgColor};
	}

`;
//#endregion


//#region [Component]
export default SContentWrapper;
//#endregion