import { PortableTextBlock } from 'sanity';
import RichText, { replaceOldStreamingProviderLinks, splitBlocksForAds, stripEmptyParagraphs } from 'src/components/richtext/Richtext';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import React from 'react';


//#region [Props]
type MainTextProps = {
	className?: string;
	mainText?: PortableTextBlock[];
	includeAds?: boolean;
	children?: React.ReactNode;
};
//#endregion

//#region [Component]
export default function MainText({ className, mainText, includeAds, children }: MainTextProps) {
	if ((mainText?.length ?? 0) === 0) {
		return null;
	}
	let _transformedMainText = stripEmptyParagraphs(mainText!);
	_transformedMainText = replaceOldStreamingProviderLinks(_transformedMainText);

	let _mainText = [_transformedMainText];
	let childrenArray: ReturnType<typeof React.Children.toArray> | undefined;



	if (includeAds && children && React.Children.count(children) > 0) {
		_mainText = splitBlocksForAds(_transformedMainText);
		if (_mainText.length > 1) {
			childrenArray = React.Children.toArray(children);
		}
	}

	return <SBodyTextContainer className={className}>
		{_mainText.map((chunk, idx) => {
			return <React.Fragment key={idx}>
				{idx > 0 && <>{childrenArray!.length >= idx ? childrenArray![idx - 1] : null}</>}
				<SBodyText value={chunk} />
			</React.Fragment>;
		})}
	</SBodyTextContainer>;
}
//#endregion

//#region [Styles]
const SBodyTextContainer = styled.div`
	max-width: 100%;

	.ad {
		margin-bottom: 1em;
	}
`;

export const CSSMainTextBlock = css`
	max-width: 100%;
	font-size: ${theme.fonts.bodytextS.size};
	line-height: 30px;
	letter-spacing: 0.32px;
	color: var(--BODYTEXTCOLOR-OVERRIDE, var(--buttontextcolor));

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		font-size: ${theme.fonts.bodytextXL.size};
		line-height: 34px;
	}

	p:first-child {
		margin-top: 0;
	}

	/*p:empty {
		min-height: 1px;
		background-color: red;
	}*/

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1em;
		a {
			margin-right: 0.5ch;
		}
	}
`;

const SBodyText = styled(RichText)`
	${CSSMainTextBlock}

`;
//#endregion