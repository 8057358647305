import IFilmnytt from "src/lib/types/Filmnytt";
import { groq } from "next-sanity";

const MARKDEFS = groq`
markDefs[]{
	...,
	_type == "internalLink" => {
		"slug": select(
			@.reference->slug != null => @.reference->slug.current,
			@.reference->mainVersionEDI != null => @.reference->mainVersionEDI,
			@.reference->file != null => @.reference->file.asset->,
			@.reference->_type == "person" => @.reference->name,
			null
		),
		"isStreaming": select(
        	@.reference->isStreaming == true => true,
			false
        ),
		"docType": @.reference->_type
	}
}
`;



export function createRichTextQueryFragment(fieldName: string): string {
    return groq`${fieldName}[] {
		...,
		_type == "inlineImage" => {
			"image": {...@.image, "asset": @.image.asset->},
			"imageText": @.imageText
		},
		_type == "quote" => {
			...
    	},
        _type == "inlineGallery" => {
            "gallery": {
                @.gallery->_type == "gallery" => {
                    ...@.gallery->,
                    "images": @.gallery->images[] {
                            ...@,
                            "asset": @.asset->
                    }
                },
                @.gallery->_type == "filmomtale" => {
                    ...@.gallery->,
                    "imagesV2": @.gallery->imagesV2[] {
                        ...@,
                        "asset": @.asset->
                    },
                    "imagesOverrideV2": @.gallery->imagesOverrideV2[] {
                        ...@,
                        "asset": @.asset->
                    },
                    "mainImageGalleryV2": @.gallery->mainImageGalleryV2[] {
                        ...@,
                        "asset": @.asset->
                    }
                }
            }
        },
        _type == "diceRow" => {
            "film": @.film-> {
              reviews
            }
        },
		${MARKDEFS}
	}`;
}

export const FRONTPAGE_NEWS = groq`*[_id =='frontpage_v2' ].articleList[@->_type =='filmnytt']-> {
	...,
	"imageV2": imageV2[] {...@, "asset": @.asset->}
}`;

export const STREAMING_NEWS = groq`*[_id =='streamingguide_v1' ].articleList[@->_type =='filmnytt']-> {
	...,
	"imageV2": imageV2[] {...@, "asset": @.asset->}
}`;

/**
 *
 * @param newsList the news list
 * @param parentId the current article displayed
 * @returns a list without nulls and without the current article
 */
export function filterOtherNews(newsList: IFilmnytt[] | undefined, parentId?: string): IFilmnytt[] | undefined {
    return newsList?.filter(news => !!news && news._id !== parentId);
}