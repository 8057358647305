import { PortableTextBlock } from 'sanity';
import RichText from 'src/components/richtext/Richtext';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
//#region [Styles]
export const CSSIngressBlock = css`
	font-size: ${theme.fonts.ingressS.size};
	font-weight: ${theme.fonts.ingressS.weight};
	line-height: 24px;
	color: var(--INGRESSCOLOR-OVERRIDE, var(--textcolor));
	margin-bottom: 30px; // 40 - lineheight diff

	@media ${theme.mq.desktop} {
		font-size: ${theme.fonts.ingressM.size};
		font-weight: ${theme.fonts.ingressM.weight};
		line-height: 38px;
		margin-bottom: 28px; // 40 - lineheight diff
	}

	p:last-child {
		margin-bottom: 0;
	}

	p:first-child {
		margin-top: 0;
	}

	p:empty {
		display: none;
	}
`;

const SRichText = styled(RichText)`
	${CSSIngressBlock}
`;
//#endregion

//#region [Props]
type IngressProps = {
	className?: string;
	ingress?: PortableTextBlock[],
};
//#endregion

//#region [Component]
export default function Ingress({ className, ingress }: IngressProps) {
	return <SRichText value={ingress} className={className}  />
}
//#endregion