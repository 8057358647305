import FilmwebSGLogoSvg from 'src/components/svg/FilmwebSGLogoSvg';
import styled from 'src/lib/styles/css';
import Link from 'next/link';
import theme from 'src/components/themes/DefaultTheme';
//#region [Styles]
const SLogoContainer = styled(Link)`
	max-width: 470px;
	margin: 30px auto;
	line-height: 1;
	display: block;
	@media ${theme.mq.mobile} {
		padding: 0 10px;
	}
`
//#endregion

//#region [Props]
type StreamingguideLogoProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function StreamingguideLogo({ className }: StreamingguideLogoProps) {
	return <SLogoContainer href="/streamingguide" className={className} aria-label="Streamingguiden">
		<FilmwebSGLogoSvg/>
	</SLogoContainer>
}
//#endregion